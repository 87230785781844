<template>
  <v-container fluid>
    <v-dialog v-if="confirmDialog" v-model="confirmDialog">
      <v-card>
        <v-card-title class="primary">
          <span class="headline">Confirm replace link for Campaign ID: {{ campaignId }}</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="1">
                <pre class="title"> FROM:</pre>
                <pre class="title"> TO:</pre>
              </v-col>
              <v-col cols="12" sm="10">
                <pre class="title"> {{ oldLink }}</pre>
                <pre class="title"> {{ newLink }}</pre>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" @click="replaceErrorUrl">
            Replace
            <v-icon>mdi-send</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="2" md="2">
        <v-select
            :items="whoAdded"
            hide-details
            label="Who added"
            dense
            solo
            :value="whoAdd"
            @input="whoAdd = $event"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2" md="2">
        <v-select
            :items="creators"
            hide-details
            label="Creator"
            dense
            solo
            :value="creator"
            @input="creator = $event"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3" md="3" offset-md="3">
        <v-pagination v-model="page" :length="pageCount" total-visible="7"></v-pagination>
      </v-col>
      <v-col cols="12" sm="1" md="1">
        <v-select
            class="pt-1 mr-0"
            v-model="itemsPerPage"
            :items="itemsPerPageOptions"
            hide-details
            dense
            solo
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="12" class="pt-0">
        <v-data-table
            :headers="headers"
            :items="errorUrls"
            :items-per-page="itemsPerPage"
            :sort-by="['creator']"
            :sort-desc="[false]"
            dense
            hide-default-footer
            @page-count="pageCount = $event"
            :page.sync="page"
            :server-items-length="total"
        >

          <template v-if="itemsPerPage > 15" v-slot:footer>
            <v-row
                justify="start"
                align="start"
                width="100%"
                class="pl-3 pr-4"
            >
              <v-row>
                <v-col cols="12" sm="6" offset-md="8" md="4">
                  <v-pagination v-model="page" :length="pageCount" total-visible="7"></v-pagination>
                </v-col>
              </v-row>
            </v-row>
          </template>

          <!--      <template v-slot:item.id="{ item }">-->
          <!--        <a target="_blank" rel="noopener noreferrer"-->
          <!--           :href="`https://admin.mgid.com/cab/goodhits/clients?client_currency=&agency=&client_id=${item.id}&login=&email=&domain=&curator=&partner=&inviter=&accompany_manager=&wagesAll=&deposit=&subnet=&color=&periodSelectInterval=&btnsubmit=Filter`"-->
          <!--        >{{ item.id}}-->
          <!--        </a>-->
          <!--      </template>-->

          <template v-slot:item.campaign_id="{ item }">
            <router-link :to='`/Optimization/Campaigns/${item.campaign_id}/edit`'>{{ item.campaign_id }}</router-link>
          </template>

          <template v-slot:item.teaserId="{ item }">
            <a target="_blank"
               :href='`https://dashboard.${item.network}/advertisers/teasers-goods/campaign_id/${item.campaign_id}/search/${item.teaserId}`'>{{
                item.teaserId
              }}</a>

          </template>

          <template v-slot:item.url="{ item }">
            <v-hover
                v-if="isAdmin"
                v-slot:default="{ hover }">
              <div>
                {{ item.url }}
                <a target="_blank"
                   :href='`https://admin.mgid.com/cab/goodhits/ghits/?curator=&login=&campaign_name=&campaign_id=${item.campaign_id}&client_id=&id=&keywordTitle=&keywordDescription=&keywordCallToAction=&searchFeedKeyword=&searchFeedProvider=&ctr_filter=&order_id=&subnet=&blocked=&status=approved&color=&activePartner=&compliant=&autocreative=&copied_teasers=&auction_type=&landing_page=&offer_name=&only_certified_offers=&approvedByNativeSpeaker=&validFormatsOnly=&useCloudinary=&donor=&has_clicks=&advertiser_name=&url=${prepareLink(item.url)}&url_radio=&rejected_type=&tier=&creator=&whenAddInterval=&moderator=&moderationTimeStartDate=&moderationTimeStartTime=&moderationTimeEndTime=&moderationTimeEndDate=&btnsubmit=Filter`'>
                  <v-icon small :style="{ visibility: hover ? 'visible': 'hidden' }">
                    mdi-file-find
                  </v-icon>
                </a>
              </div>

            </v-hover>
            <div v-else>
              {{ item.url }}
            </div>
          </template>

          <template v-slot:item.campaignUrl="{ item }">
            <v-hover
                v-if="isAdmin"
                v-slot:default="{ hover }">
              <div>
                {{ item.campaignUrl }}
                <v-icon small :style="{ visibility: hover ? 'visible': 'hidden' }"
                        @click="onClickChangeLink(item.campaign_id, item.campaignUrl, item.url)">mdi-swap-horizontal
                </v-icon>
              </div>

            </v-hover>
            <div v-else>
              {{ item.campaignUrl }}
            </div>
          </template>
          <!--
          https://admin.mgid.com/cab/goodhits/ghits/?curator=&login=&campaign_name=&campaign_id=11137845&client_id=&id=&keywordTitle=&keywordDescription=&keywordCallToAction=&searchFeedKeyword=&searchFeedProvider=&ctr_filter=&order_id=&subnet=&blocked=&status=approved&color=&activePartner=&compliant=&autocreative=&copied_teasers=&auction_type=&landing_page=&offer_name=&only_certified_offers=&approvedByNativeSpeaker=&validFormatsOnly=&useCloudinary=&donor=&has_clicks=&advertiser_name=&url=https%3A%2F%2Fwww.cpctracklnk.com%2F15GtsH%3F&url_radio=&rejected_type=&tier=&creator=&whenAddInterval=&moderator=&moderationTimeStartDate=&moderationTimeStartTime=&moderationTimeEndTime=&moderationTimeEndDate=&btnsubmit=Filter
           -->
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/utils/api'
import {
  // IFACE_DRAWER_LEFT_OFF,
  // IFACE_DRAWER_LEFT_ON,
  IFACE_URL_CHECKER_LAST_UPDATE,
} from '@/store/actions/iface'
// import { IFACE_SPENDING_LAST_UPDATE } from '@/store/actions/iface'

export default {
  name: 'ErrorInUrl',
  data () {
    return {
      confirmDialog: false,
      itemsPerPageOptions: [5, 10, 15, 20, 30, 50, 100],
      itemsPerPage: 15,
      page: 1,
      pageCount: 0,
      total: 0,
      creator: 'All',
      whoAdd: 'All',
      creators: [],
      whoAdded: [],
      headers: [
        {text: 'Network', value: 'network', sortable: false,},
        {text: 'Cab ID', value: 'client_id', sortable: false,},
        {text: 'Name', value: 'networkName', sortable: false,},
        {text: 'Campaign ID', value: 'campaign_id', sortable: false,},
        {text: 'Who added', value: 'who_added'},
        {text: 'Creator', value: 'creator'},
        {text: 'Teaser Id', value: 'teaserId', sortable: false,},
        {text: 'Optimisation URL', value: 'campaignUrl'},
        {text: 'Teaser URL', value: 'url'},
        {text: 'Error Domain ', value: 'not_allowed_link'},
      ],
      errorUrls: [],

      campaignId: null,
      oldLink: null,
      newLink: null,

      initWhoAdded: false,
      initCreator: false
    }
  },

  created () {
    document.title = 'Error in URL'
    // console.log('created', this.$route)

    if (this.$route.query.creator) {
      this.initCreator = true
      this.creator = this.$route.query.creator
    }

    if (this.$route.query.whoAdd) {
      this.initWhoAdded = true
      this.whoAdd = this.$route.query.whoAdd
    }
  },

  computed: {
    isAdmin () {return this.$store.getters['user/isAdmin']},
  },

  mounted () {
    // this.$store.commit(IFACE_DRAWER_LEFT_OFF)
    this.fetchData()
  },

  beforeDestroy () {
    // this.$store.commit(IFACE_DRAWER_LEFT_ON)
  },

  methods: {
    async fetchData () {
      try {
        const res = await api.getErrorInUrl(this.itemsPerPage, this.page - 1,
            this.creator === 'All' ? undefined : this.creator,
            this.whoAdd === 'All' ? undefined : this.whoAdd,
        )

        this.$set(this, 'creators', ['All'].concat(res.data.creators))
        this.$set(this, 'whoAdded', ['All'].concat(res.data.whoAdded))
        this.$set(this, 'errorUrls', res.data.errorUrls)
        this.$set(this, 'total', res.data.total)

        window.console.info('RES', res.data)
        this.$store.commit(IFACE_URL_CHECKER_LAST_UPDATE, Math.round((Date.now() / 1000 - res.data.lastUpdate) / 60))
      } catch (e) {
        window.console.error('Spending mounted', e)
      }
    },

    async replaceErrorUrl () {
      try {
        const req = {
          campaign_id: this.campaignId,
          type: 'string',
          name: 'link',
          value: this.newLink
        }
        await api.updateValue(req)
        this.confirmDialog = false
      } catch (e) {
        window.console.error('replaceErrorUrl', e)
      }
    },

    prepareLink (link) {
      const match = link.match(/^"(https:\/\/[^?]+\?).*$/)
      if (Array.isArray(match) && match.length == 2) {
        return match[1]
      } else {
        return link
      }
    },

    onClickChangeLink (campaignId, from, to) {
      this.campaignId = campaignId
      this.oldLink = from.replace(/"/g, '')
      this.newLink = this.prepareLink(to)
      this.confirmDialog = true
    }
  },

  watch: {
    $route: {
      handler () {
        // console.log('$route$route$route$route: ', this.$route)
        this.fetchData()
        // if (this.$route.name === 'Edit') {
        //   if (this.$route.params.campaignId) {
        //     this.campaignId = this.$route.params.campaignId
        //   }
        //   this.prepareCampaignAndShowDialog(this.$route.params.campaign)
        // }
        // if (this.$route.name === 'Optimization') {
        //   this.campaignEditorDialog = false
        // }
      },
      deep: true,
    },

    itemsPerPage () {
      this.fetchData()
    },
    page () {
      this.fetchData()
    },
    whoAdd (whoAdd) {
      if (this.initWhoAdded) {
        this.initWhoAdded = false
        return
      }

      const q = {}

      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'creator')) {
        q.creator = this.$route.query.creator
      }

      if (Object.prototype.hasOwnProperty.call(this.$route.query,'whoAdd') ) {
        if (whoAdd !== 'All') {
          q.whoAdd = whoAdd
        }
      } else {
        q.whoAdd = whoAdd
      }

      this.$router.push({query: q})

    },
    creator (creator) {
      if (this.initCreator) {
        this.initCreator = false
        return
      }

      const q = {}

      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'whoAdd')) {
        q.whoAdd = this.$route.query.whoAdd
      }

      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'creator')) {
        if (creator !== 'All') {
          q.creator = creator
        }
      } else {
        q.creator = creator
      }

      this.$router.push({query: q})
    }
  }
}
</script>

<style scoped>

</style>